export default defineNuxtRouteMiddleware( async ({ params }) => {
    const commercialStore = useCommercialStore()
    const response = await commercialStore.getIntrumId( params.id )
    if (response == '404_page_not_found') {
        throw createError({
            statusCode: 404,
            statusMessage: 'Page Not Found'
        })
    }else {
        let { link } = response
        return link
    }
})